import React from "react";
import { Container } from "react-bootstrap";

//importacion de imagenes
import NoticiasFoto from "../img/noticiasFoto.png";

//IMPORTACION DE COMPONENTES

export function Noticias() {
  return (
    <Container className="d-flex flex-column justify-content-center align-items-center">
      <img src={NoticiasFoto} alt="NexoTests" className="img-fluid rounded" />
      <div className="d-flex flex-column justify-content-center align-items-center text-center">
        <h2 className="color-principal-texto py-3">Noticias</h2>
        <p>
          En esta sección, podrás encontrar las últimas noticias relacionadas
          con oposiciones, cursos, convocatorias y otros temas de interés.
        </p>
        <p>
          Nuestra misión es mantenerte informado y al día con las novedades más
          relevantes en el ámbito de la educación y las oportunidades
          profesionales. Estamos comprometidos en proporcionarte información
          precisa y oportuna para que puedas tomar decisiones informadas en tu
          camino hacia el éxito.
        </p>
        <p>
          En estos momentos, estamos ansiosos por recibir las mejores noticias
          del año 2024. Creemos que estas noticias serán de gran ayuda para
          nuestros opositores y estudiantes, y estamos dedicados a compartir ese
          conocimiento contigo. ¡Mantente atento a nuestras actualizaciones en nuestras redes!
        </p>
      </div>
    </Container>
  );
}
