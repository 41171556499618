import React from "react";

//IMPORTACION DE IMAGENES
import mainFoto from "../img/mainFoto.png";
import { Container } from "react-bootstrap";

//IMPORTACION DE COMPONENTES
import NoticiasImportantes from "../components/NoticiasImportantes";

export function Main() {
  return (
    <Container className="d-flex flex-column justify-content-center align-items-center">
      <img src={mainFoto} alt="" className="img-fluid rounded" />
      <div className="w-100 my-5 text-center">
        <h1 className="display-4 animate-charcter font-weight-bold">
          ACADEMIA DE TEST ONLINE
        </h1>
      </div>
      <div className="d-flex py-3">
        <h2>¿Qué es </h2>
        <h2 className="color-principal-texto pb-3 px-2">NexoTests</h2>
        <h2>?</h2>
      </div>
      <div className="text-center">
        <p>
          NexoTests es una academia principal que abraza diversas sub-academias.
          Cada una de estas sub-academias se especializa en un área específica
          de conocimiento, con el compromiso de brindar educación de alta
          calidad y fomentar el aprendizaje continuo.
        </p>
        <p>
          En NexoTests, entendemos que la educación es un viaje único para cada
          estudiante, y es por eso que ofrecemos una variedad de programas y
          cursos diseñados para satisfacer las necesidades individuales de
          nuestros alumnos. Desde la preparación de exámenes hasta el desarrollo
          de habilidades especializadas, nuestra misión es ayudarte a alcanzar
          tus metas educativas.
        </p>
        <p>
          Cada academia se especializa en un tema diferente, creando así un
          universo educativo diverso y fascinante dentro de <b>NEXOTESTS</b>.
        </p>
        <p>
          Ya sea que estés interesado en el ascenso a cabo, el acceso a la tropa
          permanente, el desarrollo web o cualquier otro campo, estamos aquí
          para brindarte el conocimiento y el apoyo que necesitas para tener
          éxito.
        </p>
      </div>
      <div className="text-center py-5">
        <h2 className="color-principal w-100 text-white py-3 rounded my-3">Resumen de Noticias</h2>
        <NoticiasImportantes />
      </div>
    </Container>
  );
}
