import React from "react";
import { Card, Container, Row, Col } from "react-bootstrap";

function NoticiasImportantes() {
  // Define tus noticias importantes aquí
  const importantNews = [
    {
      title: "Aprobada la convocatoria de Ascenso a Cabo 2023",
      content: "+1500 plazas para el ascenso a cabo 2023, el examen será aproximadamente en febrero.",
      fecha: "20 de septiembre de 2023", // Agrega la ruta de la imagen
    },
    {
      title: "Se pone en marcha la academia online NexoTests",
      content: "La academia online NexoTests abre sus puertas para ayudarte a preparar tus oposiciones.",
      fecha: "1 de enero de 2024", // Agrega la ruta de la imagen
    },
    {
      title: "Publicadas las vacantes para la Unidad Militar de Emergencias (UME) 2024",
      content: "Se publica la primera tanda de vacantes para la UME en 2024.",
      fecha: "09 de enero de 2024", // Agrega la ruta de la imagen
    },
  ];

  // Función para renderizar las noticias importantes como tarjetas
  const NoticiasImportantes = () => {
    return importantNews.map((news, index) => (
      <Col key={index} md={4} className="my-3">
        <Card className="custom-card">
          <Card.Body>
            <Card.Title>
              <a href="#/">{news.title}</a>
            </Card.Title>
            <Card.Text>{news.content}</Card.Text>
            <Card.Text>{news.fecha}</Card.Text>
          </Card.Body>
        </Card>
      </Col>
    ));
  };

  return (
    <Container>
      <Row>{NoticiasImportantes()}</Row>
    </Container>
  );
}

export default NoticiasImportantes;
