import React from "react";
import Container from "react-bootstrap/Container";

import "./styles.css";

import SerigrafiaSF from "../img/serigrafiaSF.png";

import {
  FaFacebook,
  FaInstagram,
  FaTiktok,
  FaTelegram,
} from "react-icons/fa";

import { FaXTwitter } from "react-icons/fa6";

function Footer() {
  return (
    <footer className="bg-body-tertiary py-4">
      <Container className="d-flex flex-column justify-content-center align-items-center ">
        <img src={SerigrafiaSF} alt="Logo" className="img-fluid pb-3" width="30%" />
            <p>
              <a href="https://www.instagram.com/nexotests?igsh=MXVmaHphb2Y4c3dxMw=="><FaFacebook size={50} className="color-principal-texto scale-up"/></a>
              <a href="https://www.instagram.com/nexotests?igsh=MXVmaHphb2Y4c3dxMw=="><FaInstagram size={50}  className="color-secundario-texto scale-up"/></a>
              <a href="https://twitter.com/NexoTests"><FaXTwitter size={50}  className="color-secundario-texto scale-up"/></a>
              <a href="https://t.me/+Q77RdFIi0JQwYjM0"><FaTiktok size={50}  className="color-secundario-texto scale-up"/></a>
              <a href="https://t.me/+Q77RdFIi0JQwYjM0" target="blank"><FaTelegram size={50}  className="color-principal-texto scale-up"/></a>
            </p>
      </Container>
      <div className="text-center py-2">
      © {new Date().getFullYear()} NexoTests, una iniciativa de Web Tek Innovations LLC. Todos los derechos reservados.
      </div>
    </footer>
  );
}

export default Footer;
