import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Container } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
//IMPORTACIONES DE ESTILOS
import "./styles.css";

//IMPORTACIONES DE VISTAS
import { Link } from "react-router-dom";
import { Main } from "../views/Main";
import { QuienesSomos } from "../views/QuienesSomos";
import { Tienda } from "../views/Tienda";
import { Noticias } from "../views/Noticias";
import { Contacto } from "../views/Contacto";

//IMPORTACIONES DE ICONOS
import { GiCorporal } from "react-icons/gi";
import { HiDesktopComputer } from "react-icons/hi";


//IMPORTACIONES DE IMAGENES
import SerigrafiaSF from "../img/serigrafiaSF.png";
import LogoSF from "../img/logoSF.png";

function App() {
  return (
    <Router>
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container>
          <Navbar.Brand to="/"><img src={LogoSF} alt="Nexotests" class="img-fluid" width="60px"/><img src={SerigrafiaSF} alt="Nexotests" class="img-fluid" width="200px"/></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Link className="nav-link color-principal-texto" to="/">
                Inicio
              </Link>
              <Link className="nav-link color-principal-texto" to="/QuienesSomos">
                ¿Quienes Somos?
              </Link>
              <NavDropdown title="Academias" id="basic-nav-dropdown">
                <NavDropdown.Item
                  href="https://ascensoacabo.com/"
                  target="blank"
                >
                  Ascenso a Cabo <GiCorporal size={20} className="color-principal-texto"/>
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2" disabled>
                  Acceso Tropa Permanente
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3" disabled>
                  Acceso Suboficiales
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item
                  href="https://webtekinnovations.com/"
                  target="blank"
                >
                  Web Tek Innovations (Desarrollo Web) <HiDesktopComputer size={20} className="color-principal-texto"/>
                </NavDropdown.Item>
              </NavDropdown>
              <Link className="nav-link color-principal-texto" to="/Tienda" disabled>
                Tienda
              </Link>
              <Link className="nav-link color-principal-texto" to="/Noticias">
                Noticias
              </Link>
              <Link className="nav-link color-principal-texto" to="/Contacto">
                Contacto
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container className="mt-4">
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/QuienesSomos" element={<QuienesSomos />} />
          <Route path="/Tienda" element={<Tienda />} />
          <Route path="/Noticias" element={<Noticias />} />
          <Route path="/Contacto" element={<Contacto />} />
        </Routes>
      </Container>
    </Router>
  );
}

export default App;
