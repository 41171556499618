import React from "react";

import { Container } from "react-bootstrap";

import QuienesSomosFoto from "../img/quienesSomosFoto.png";

//Importacion de iconos
import { FaHistory, FaChalkboardTeacher, FaCertificate } from "react-icons/fa";

export function QuienesSomos() {
  return (
    <Container className="d-flex flex-column justify-content-center align-items-center">
      <img
        src={QuienesSomosFoto}
        alt="NexoTests"
        className="img-fluid rounded"
      />
      <div className="d-flex flex-column justify-content-center align-items-center text-center py-3">
        <div className="d-flex py-3 justify-content-center align-items-center">
          <h2 className="color-principal-texto px-2">NexoTests</h2>
          <h2>¿Quienes Somos?</h2>
          <FaHistory size={50} className="color-secundario-texto px-2 display-4" />
        </div>
        <div className="text-center">
          <p>
            NexoTests es una academia de educación fundada en el año 2024 por un
            grupo de militares con un profundo compromiso por la formación y
            el desarrollo profesional de nuestros estudiantes. Nuestra historia
            se origina en la pasión por la enseñanza y el deseo de proporcionar
            oportunidades educativas excepcionales.
          </p>
          <p>
            En NexoTests, creemos en la excelencia educativa y en la importancia
            de ayudar a nuestros alumnos a alcanzar sus objetivos académicos y
            profesionales. Contamos con un equipo de profesores altamente
            calificados y profesionales, dedicados a brindar una educación de
            alta calidad y apoyar a nuestros estudiantes en cada paso de su
            viaje educativo.
          </p>
          <p>
            Cada una de nuestras sub-academias se especializa en un área
            específica de conocimiento y está respaldada por años de experiencia
            y conocimiento en su respectivo campo. Nuestro enfoque en la
            excelencia académica y el compromiso con el éxito de nuestros
            estudiantes nos distingue.
          </p>
          <p>
            En NexoTests, estamos comprometidos en proporcionar una experiencia
            educativa integral que prepara a nuestros alumnos para alcanzar sus
            metas y avanzar en sus carreras. Ya sea que estés buscando ascender
            en el ejército, adquirir nuevas habilidades o expandir tu
            conocimiento en áreas específicas, estamos aquí para apoyarte en
            cada paso del camino.
          </p>
        </div>
        <div className="d-flex py-3">
          <h2>El Método</h2>
          <h2 className="color-principal-texto px-2">NexoTests</h2>
          <FaChalkboardTeacher size={50} className="color-secundario-texto display-4" />
        </div>
        <div className="text-center">
          <p>
            NEXOTESTS, como academia matriz establece un estándar único: el
            método de estudio a través de exámenes tipo test. Creemos firmemente
            en esta metodología de aprendizaje, que ha demostrado ser una forma
            estructurada y eficiente de adquirir conocimientos y prepararse para
            desafíos académicos y profesionales.
          </p>
          <p>
            Nuestro enfoque en los exámenes tipo test no solo se basa en la
            evaluación, sino también en el proceso de aprendizaje en sí. Cada
            una de nuestras sub-academias abraza esta metodología y la integra
            en sus programas educativos. Esto significa que nuestros estudiantes
            experimentan una forma coherente y efectiva de aprender, que les
            ayuda a desarrollar habilidades críticas, consolidar su conocimiento
            y medir su progreso de manera continua.
          </p>
          <p>
            La ventaja de este enfoque es que prepara a nuestros estudiantes
            para enfrentar desafíos diversos, desde exámenes de ascenso militar
            hasta pruebas de certificación en otros campos. Nos enorgullece
            ofrecer una experiencia educativa que no solo les brinda
            conocimientos sólidos, sino también las habilidades necesarias para
            tener éxito en cualquier entorno de evaluación.
          </p>
        </div>
        <div  className="d-flex py-3">
          <h2>¿Por Qué</h2>
          <h2 className="color-principal-texto px-2">NexoTests</h2>
          <h2>?</h2>
          <FaCertificate size={50} className="color-secundario-texto px-2 display-4" />
        </div>
        <div className="text-center">
          <p>
            Más que una simple opción educativa, <b>NexoTests</b> representa una
            decisión consciente hacia la excelencia académica. Desde la
            diversidad de las sub-academias hasta el método de exámenes tipo
            test, cada aspecto de nuestra institución se entrelaza de manera
            armoniosa para ofrecer una experiencia educativa incomparable.
          </p>
          <p>
            En NexoTests, no consideramos la educación como un mero proceso,
            sino como un viaje que nuestros estudiantes emprenden con el
            compromiso de alcanzar el más alto nivel de conocimiento y
            habilidades. Nuestra dedicación a la calidad, la diversidad y la
            innovación en la educación es lo que nos distingue como una opción
            educativa líder en su clase.
          </p>
          <p>
            Creemos que la excelencia académica es el resultado de la
            dedicación, el esfuerzo y la pasión por aprender. Nuestros
            estudiantes son el corazón de nuestra comunidad educativa, y estamos
            aquí para brindarles las herramientas y el apoyo que necesitan para
            alcanzar sus metas y forjar un futuro exitoso.
          </p>
        </div>
      </div>
    </Container>
  );
}
