import React from "react";
import { Container } from "react-bootstrap";

//importacion de imagenes
import TiendaFoto from "../img/tiendaFoto.png";

export function Tienda() {
  return (
    <Container>
      <img src={TiendaFoto} alt="NexoTests" className="img-fluid rounded" />
      <div className="text-center py-5">
        <h2 className="color-principal-texto py-3">¡Estamos Construyendo Nuestra Tienda en Línea!</h2>
        <p>
          En NexoTests, queremos compartir una emocionante noticia contigo:
          estamos trabajando en nuestra propia tienda en línea. Sí, lo has oído
          bien. Pronto podrás explorar un espacio digital donde encontrarás todo
          lo que necesitas para tu preparación y mucho más.
        </p>

        <p>
          Imagina una tienda que ofrece algo más que productos. Estamos
          construyendo un lugar donde puedas encontrar recursos de calidad
          diseñados específicamente para tu éxito. Desde temario personalizado
          que se adapta a tus necesidades hasta una selección de efectos
          militares y accesorios que te ayudarán en tu camino. También tendrás
          la oportunidad de descubrir parches únicos y otros artículos
          coleccionables que celebran tu compromiso y dedicación.
        </p>

        <p>
          Lo mejor de todo es que esta tienda en línea será un reflejo de
          nuestra pasión por el aprendizaje y el servicio militar. Queremos
          proporcionarte no solo productos, sino también una experiencia que te
          ayude a avanzar en tus objetivos académicos y militares.
        </p>

        <p>
          Estamos trabajando duro para hacer realidad esta tienda y estamos
          ansiosos por compartirla contigo pronto. Mantente atento para obtener
          más detalles y actualizaciones sobre nuestro lanzamiento. ¡Te
          prometemos que valdrá la pena la espera!
        </p>
      </div>
    </Container>
  );
}
